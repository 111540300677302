import React from "react";
import "./Bars.css";
import BarSwiper from "../Swiper/Swiper";
import theCopperOne from "../../images/The-Copper-Pot-img-1.webp";
import theCopperTwo from "../../images/The-Copper-Pot-img-2.webp";
import theCopperThree from "../../images/The-Copper-Pot-img-3.webp";
import carkonianOne from "../../images/The-Corkonian-Irish-Pub-img-1.webp";
import carkonianTwo from "../../images/The-Corkonian-Irish-Pub-img-2.webp";
import carkonianThree from "../../images/The-Corkonian-Irish-Pub-img-3.webp";
import fattyOne from "../../images/Fatty's-Irish-Pub-img-1.webp";
import fattyTwo from "../../images/Fatty's-Irish-Pub-img-2.webp";
import fattyThree from "../../images/Fatty's-Irish-Pub-img-3.webp";
import stadionOne from "../../images/Stadion-an-der-Schleissheimerstrasse-img-1.webp";
import stadionTwo from "../../images/Stadion-an-der-Schleissheimerstrasse-img-2.webp";
import stadionThree from "../../images/Stadion-an-der-Schleissheimerstrasse-img-3.webp";

const Bars = () => {
  return (
    <section className="bars">
      <h2 className="col-yellow bars__header">Beste Bars</h2>
      <div className="bars__container">
        <div className="bar grid-container bar-one">
          <h2 className="header bar__header col-yellow">The Copper Pot</h2>
          <div className="bar__info info">
            <div className="bar__img-container pc">
              <img
                src={theCopperOne}
                alt="The Copper Pot Innenraum"
                title="The Copper Pot Innenraum"
                width="165"
                height="128"
                className="bar__img-one"
              />
              <img
                src={theCopperTwo}
                alt="The Copper Pot Innenraum"
                title="The Copper Pot Innenraum"
                width="165"
                height="128"
                className="bar__img-two"
              />
              <img
                src={theCopperThree}
                alt="The Copper Pot Innenraum"
                title="The Copper Pot Innenraum"
                width="362"
                height="221"
                className="bar__img-three"
              />
            </div>

            <div className="bar__text-container">
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Adresse:</span>
                <br></br>Kaiserstraße 57, 60329 Frankfurt am Main, Deutschland
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Bewertung:</span>
                <br></br>4.7/5
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Vorteile:</span>
                <br></br>Hervorragender Ort für Sportwetten, vielfältiges
                Getränke- und Snackmenü, gemütliche Atmosphäre, Übertragungen
                aller großen Sportereignisse.
              </p>
              <BarSwiper
                imgOne={theCopperOne}
                imgTwo={theCopperTwo}
                imgThree={theCopperThree}
                name={"The Copper Pot"}
              />

              <div className="bar__bottom-container">
                <p className="t-15 col-white bar__about-text">
                  <span className="t-15 col-yellow">Beschreibung:</span>
                  <br></br>The Copper Pot ist eine der beliebtesten Sportbars in
                  Frankfurt. Hier finden Sie eine gemütliche Atmosphäre, in der
                  jeder Winkel vom Geist des Sports durchdrungen ist. Die Bar
                  bietet eine große Auswahl an Getränken und Snacks sowie die
                  Möglichkeit, direkt in der Bar Wetten auf Sportereignisse zu
                  platzieren. The Copper Pot ist bekannt für seine
                  Live-Übertragungen aller wichtigen Sportereignisse, sei es
                  Fußball, Basketball oder Tennis. Dies ist der ideale Ort für
                  diejenigen, die das Spiel in Gesellschaft Gleichgesinnter
                  genießen und auf ihr Lieblingsteam wetten möchten.
                </p>
                <a
                  href="https://www.tripadvisor.ru/Attraction_Review-g187371-d16649128-Reviews-The_Copper_Pot-Cologne_North_Rhine_Westphalia.html"
                  className="bar__reserve col-yellow"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reserve
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="bar grid-container bar-two">
          <h2 className="header bar__header col-yellow">
            The Corkonian Irish Pub
          </h2>
          <div className="bar__info info">
            <div className="bar__img-container pc">
              <img
                src={carkonianOne}
                alt="The Corkonian Irish Pub"
                title="The Corkonian Irish Pub"
                width="165"
                height="128"
                className="bar__img-one"
              />
              <img
                src={carkonianTwo}
                alt="The Corkonian Irish Pub"
                title="The Corkonian Irish Pub"
                width="165"
                height="128"
                className="bar__img-two"
              />
              <img
                src={carkonianThree}
                alt="The Corkonian Irish Pub"
                title="The Corkonian Irish Pub"
                width="362"
                height="221"
                className="bar__img-three"
              />
            </div>
            <div className="bar__text-container">
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Adresse:</span>
                <br></br>Alter Markt 7, 50667 Köln, Deutschland
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Bewertung:</span>
                <br></br>4.5/5
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Vorteile:</span>
                <br></br>Authentische irische Atmosphäre, große Auswahl an Bier,
                Live-Sportübertragungen, Möglichkeit, Wetten zu platzieren.
              </p>
              <BarSwiper
                imgOne={carkonianOne}
                imgTwo={carkonianTwo}
                imgThree={carkonianThree}
                name={"The Corkonian Irish Pub"}
              />

              <div className="bar__bottom-container">
                <p className="t-15 col-white bar__about-text">
                  <span className="t-15 col-yellow">Beschreibung:</span>
                  <br></br>The Corkonian Irish Pub ist ein Stück Irland im
                  Herzen von Köln. Hier können Sie die echte irische Atmosphäre
                  genießen, die besten Biersorten probieren und
                  Sportübertragungen auf großen Bildschirmen verfolgen. Die Bar
                  bietet auch die Möglichkeit, Wetten auf Sportereignisse zu
                  platzieren, was das Anschauen der Spiele noch spannender
                  macht. The Corkonian Irish Pub ist der perfekte Ort für
                  Sportliebhaber und Fans der irischen Kultur, wo immer eine
                  freundliche und fröhliche Atmosphäre herrscht.
                </p>
                <a
                  href="https://www.tripadvisor.ru/Attraction_Review-g187371-d5947254-Reviews-The_Corkonian_Irish_Pub-Cologne_North_Rhine_Westphalia.html"
                  className="bar__reserve col-yellow"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reserve
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="bar grid-container bar-three">
          <h2 className="header bar__header col-yellow">Fatty's Irish Pub</h2>
          <div className="bar__info info">
            <div className="bar__img-container pc">
              <img
                src={fattyOne}
                alt="Fatty's Irish Pub"
                title="Fatty's Irish Pub"
                width="165"
                height="128"
                className="bar__img-one"
              />
              <img
                src={fattyTwo}
                alt="Fatty's Irish Pub"
                title="Fatty's Irish Pub"
                width="165"
                height="128"
                className="bar__img-two"
              />
              <img
                src={fattyThree}
                alt="Fatty's Irish Pub"
                title="Fatty's Irish Pub"
                width="362"
                height="221"
                className="bar__img-three"
              />
            </div>
            <div className="bar__text-container">
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Adresse:</span>
                <br></br>Friedrichstraße 113, 10117 Berlin, Deutschland
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Bewertung:</span>
                <br></br>4.6/5
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Vorteile:</span>
                <br></br>Geräumige Räume, vielfältiges Menü, viele Bildschirme
                für Sportübertragungen, zentrale Lage, Möglichkeit, Sportwetten
                zu platzieren.
              </p>
              <BarSwiper
                imgOne={fattyOne}
                imgTwo={fattyTwo}
                imgThree={fattyThree}
                name={"Fatty's Irish Pub"}
              />

              <div className="bar__bottom-container">
                <p className="t-15 col-white bar__about-text">
                  <span className="t-15 col-yellow">Beschreibung:</span>
                  <br></br>Fatty's Irish Pub ist eine geräumige Sportbar in
                  Berlin, die Besucher mit ihrer entspannten Atmosphäre und
                  hervorragenden Bedingungen für das Anschauen von
                  Sportereignissen anzieht. Die Bar bietet ein vielfältiges
                  Menü, das Snacks und Getränke für jeden Geschmack umfasst. Mit
                  zahlreichen Bildschirmen im gesamten Lokal verpassen Sie
                  keinen Moment des Spiels. Die Möglichkeit, Wetten auf
                  Sportereignisse zu platzieren, macht Fatty's Irish Pub noch
                  attraktiver für Liebhaber des Nervenkitzels. Dies ist der
                  ideale Ort, um sich mit Freunden zu treffen und
                  Sportveranstaltungen zu genießen.
                </p>
                <a
                  href="https://www.tripadvisor.ru/Attraction_Review-g187373-d5945971-Reviews-Fatty_s_Irish_Pub-Dusseldorf_North_Rhine_Westphalia.html"
                  className="bar__reserve col-yellow"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reserve
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="bar grid-container bar-four">
          <h2 className="header bar__header col-yellow">
            Stadion an der Schleissheimerstrasse
          </h2>
          <div className="bar__info info">
            <div className="bar__img-container pc">
              <img
                src={stadionOne}
                alt="Stadion an der Schleissheimerstrasse"
                title="Stadion an der Schleissheimerstrasse"
                width="165"
                height="128"
                className="bar__img-one"
              />
              <img
                src={stadionTwo}
                alt="Stadion an der Schleissheimerstrasse"
                title="Stadion an der Schleissheimerstrasse"
                width="165"
                height="128"
                className="bar__img-two"
              />
              <img
                src={stadionThree}
                alt="Stadion an der Schleissheimerstrasse"
                title="Stadion an der Schleissheimerstrasse"
                width="362"
                height="221"
                className="bar__img-three"
              />
            </div>
            <div className="bar__text-container">
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Adresse:</span>
                <br></br>Schleissheimer Str. 82, 80797 München, Deutschland
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Bewertung:</span>
                <br></br>4.8/5
              </p>
              <p className="t-15 col-white">
                <span className="t-15 col-yellow">Vorteile:</span>
                <br></br>Riesige Bildschirme, Übertragungen aller
                Sportereignisse, ausgezeichnete Küche, Möglichkeit, Sportwetten
                zu platzieren, Live-Musik.
              </p>
              <BarSwiper
                imgOne={stadionOne}
                imgTwo={stadionTwo}
                imgThree={stadionThree}
                name={"Stadion an der Schleissheimerstrasse"}
              />

              <div className="bar__bottom-container">
                <p className="t-15 col-white bar__about-text">
                  <span className="t-15 col-yellow">Beschreibung:</span>
                  <br></br>Stadion an der Schleissheimerstrasse ist nicht nur
                  eine Sportbar, sondern ein wahrer Tempel für alle
                  Sportliebhaber. Hier erwarten Sie riesige Bildschirme, auf
                  denen alle wichtigen Sportereignisse übertragen werden, sei es
                  Fußball, Hockey oder Basketball. Die Bar ist bekannt für ihre
                  ausgezeichnete Küche und die große Auswahl an Getränken. Für
                  diejenigen, die dem Spiel zusätzliche Spannung verleihen
                  möchten, gibt es die Möglichkeit, Sportwetten zu platzieren.
                  Live-Musik und Themenabende machen den Besuch dieser Bar noch
                  unvergesslicher. Stadion an der Schleissheimerstrasse ist ein
                  Ort, an dem Sport und Unterhaltung verschmelzen und eine
                  einzigartige Atmosphäre schaffen.
                </p>
                <a
                  href="https://www.tripadvisor.ru/Attraction_Review-g187309-d5945947-Reviews-Stadion_an_der_Schleissheimerstrasse-Munich_Upper_Bavaria_Bavaria.html"
                  className="bar__reserve col-yellow"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reserve
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bars;
