import React from "react";
import "./Main.css";
import AboutUs from "../AboutUs/AboutUs";
import Bars from "../Bars/Bars";
import Popup from "../Popup/Popup";
import mainCover from "../../images/main-cover.webp";

const MainCover = () => {
  return (
    <main className="main">
      {/* <Popup/> */}
      <section className="main__cover">
        <div className="main__image-with-text">
          <h1 className="col-yellow main__header">
            Willkommen bei Best Sports Bars Germany, Ihrem ultimativen Guide zu
            den besten Sportbars im ganzen Land.
          </h1>
          <div className="main__img-container">
            <img
              src={mainCover}
              alt="people having fun"
              width="528"
              height="277"
              className="main__image"
            />
          </div>
        </div>
        <p className="t-24 main__text">
          Wir sind bestrebt, Ihnen dabei zu helfen, den perfekten Ort zu finden,
          um Ihre Lieblingsspiele zu sehen, Wetten zu platzieren und eine
          großartige Atmosphäre zu genießen. Unser Team von Sportbegeisterten
          und Bar-Kennern arbeitet unermüdlich daran, Ihnen detaillierte
          Bewertungen zu liefern, um Ihnen das bestmögliche Erlebnis zu
          garantieren.
        </p>
      </section>
      <AboutUs />
      <Bars />
    </main>
  );
};

export default MainCover;
