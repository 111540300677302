import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Swiper.css";

const BarSwiper = ({ imgOne, imgTwo, imgThree, name }) => {
  return (
    <Swiper
      modules={[Pagination]}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="deal__swiper mob"
    >
      <SwiperSlide className="slide">
        <img
          src={imgOne}
          alt={name}
          title={name}
          width="316"
          height="193"
          className="bar__img"
        />
      </SwiperSlide>
      <SwiperSlide className="slide">
        <img
          src={imgTwo}
          alt={name}
          title={name}
          width="316"
          height="193"
          className="bar__img"
        />
      </SwiperSlide>
      <SwiperSlide className="slide">
        <img
          src={imgThree}
          alt={name}
          title={name}
          width="316"
          height="193"
          className="bar__img"
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default BarSwiper;