import React from "react";
import "./AboutUs.css";
import imgOne from "../../images/about-img-1.webp";
import imgTwo from "../../images/about-img-2.webp";

const AboutUs = () => {
  return (
    <section className="about">
      <div className="grid-container about-us">
        <h2 className="header about__header col-yellow">Über uns</h2>
        <div className="info about__info">
          <p className="t-18 col-white about__text">
            Wir verstehen die Bedeutung einer großartigen Sportbar, in der die
            Aufregung des Spiels mit exzellentem Service, köstlichem Essen und
            einer lebhaften Menge kombiniert wird. Ob Einheimischer oder
            Besucher, unser Ziel ist es, Sie zu den besten Orten für
            Sportunterhaltung zu führen. Unsere Website wurde erstellt, um Ihnen
            zu helfen, den idealen Ort für Sportfans zu finden, wo Sie Wetten
            platzieren und Spiele genießen können.
          </p>
          <div className="about__img-container">
            <img
              src={imgOne}
              alt="Barinterieur"
              title="Barinterieur"
              width="285"
              height="164"
              className="about__img"
            />
          </div>
        </div>
      </div>

      <div className="about-us grid-container">
        <h2 className="header about__header col-yellow">Unsere Ziele</h2>
        <div className="about__info info">
          <ul className="about__link-container">
            <li className="t-18 col-white about__link">
              Bereitstellung genauer und aktueller Informationen über die besten
              Sportbars in Deutschland.
            </li>
            <li className="t-18 col-white about__link">
              Objektive und detaillierte Bewertungen jedes Lokals
              sicherzustellen.
            </li>
            <li className="t-18 col-white about__link">
              Sportfans dabei zu helfen, die besten Orte für das Ansehen von
              Spielen und das Platzieren von Wetten zu finden.
            </li>
            <li className="t-18 col-white about__link">
              Hohe Qualitätsstandards in unseren Empfehlungen zu wahren.
            </li>
          </ul>
          <div className="about__img-container">
            <img
              src={imgTwo}
              alt="Barinterieur"
              title="Barinterieur"
              width="285"
              height="164"
              className="about__img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
