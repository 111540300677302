import React from "react";
import "./Footer.css";
import age from "../../images/age.svg";
import telegram from "../../images/telegram.svg";
import inst from "../../images/inst.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer" id="contacts">
      <div className="footer__top-container">
        <div className="footer__contacts">
          <h3 className="footer__header">Kontakte</h3>
          <p className="t-20">
            Wenn Sie mit uns Kontakt aufnehmen oder uns eine Rückmeldung geben
            möchten, sind Sie herzlich willkommen! Sie können uns per E-Mail
            kontaktieren:
          </p>
          <a
            href="mailto:wettenguru-de@gmail.com"
            className="t-20 footer__mail-link"
            target="_blank"
            rel="noreferrer"
          >
            wettenguru-de@gmail.com
          </a>
        </div>
        <div className="footer__cookie">
          <p className="t-20">
            Sie werden bei uns kein echtes Geld gewinnen können, alle
            Aktivitäten dienen nur der Unterhaltung
          </p>
          <p className="t-20">
            „wettenguru-de.com“ - Verwendet Cookies, um Ihnen das bestmögliche
            Erlebnis zu bieten. Wenn Sie auf der Website bleiben, stimmen Sie
            der Verwendung von Cookies zu. Um mehr zu erfahren
          </p>
          <Link to="/cookies" className="t-20 footer__more-info">
            Mehr Infos
          </Link>
        </div>
      </div>
      <div className="footer__link-container">
        <Link to="/privacy" className="t-20 footer__privacy">
          Datenschutzbestimmungen
        </Link>
        <div className="footer__social">
          <a href="#">
            <img src={telegram} alt="telegram icon" width="32" height="32" />
          </a>
          <a href="#">
            <img src={inst} alt="instagram icon" width="32" height="32" />
          </a>
        </div>
        <img
          src={age}
          alt="age limit 18+"
          className="footer__age"
          width="64"
          height="64"
        />
      </div>
    </footer>
  );
};

export default Footer;
